import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function EnrollmentBtnTooltip({ game, showAlways, children }) {
    if (!game.is_enrollment_prohibited && !showAlways) {
        return <>{children}</>;
    }

    let tooltipText = "Забронировать место";
    if (game.is_enrollment_prohibited) {
        if (game.prohibition_reason) {
            tooltipText = game.prohibition_reason.name;
        } else {
            tooltipText = game.is_waiting_list ? "В лист ожидания" : "Стол заполнен";
        }
    }

    const tooltip = (props) => (
        <Tooltip id="enrollment-btn-tooltip" {...props}>
            {tooltipText}
        </Tooltip>
    );

    return <OverlayTrigger overlay={tooltip}>{children}</OverlayTrigger>;
}
