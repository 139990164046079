import EnrollmentBtnTooltip from "./enrollmentbtntooltip";

export default function EnrollmentButton({ game, onEnrollment, setShowEnrollment, className }) {
    if (!onEnrollment) {
        return null;
    }
    return (
        <EnrollmentBtnTooltip game={game}>
            <button
                className={
                    "btn btn-wide-padding " +
                    (game.is_enrollment_prohibited && !game.is_waiting_list ? "btn-orange" : "btn-primary") +
                    (className ? " " + className : "")
                }
                onClick={() => setShowEnrollment(true)}
            >
                {game.is_waiting_list ? "В лист ожидания" : "Забронировать место"}
            </button>
        </EnrollmentBtnTooltip>
    );
}
